import { ComponentPropsWithoutRef, forwardRef, useEffect, useState } from 'react';

import { NewIcon } from 'design-system';
import { cn } from 'tailwind-config';

export type CoachMarkProps = ComponentPropsWithoutRef<'div'> & {
  position?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-right'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-left';
  visible?: boolean;
  onClose?: () => void;
  message?: string;
  element?: React.ReactNode;
};

type PositionClassesObj = Record<Required<CoachMarkProps>['position'], CoachMarkProps['className']>;

const positionArrowClasses = {
  top: '-top-24 left-1/2 -translate-x-1/2',
  bottom: 'bottom-0 left-1/2 -translate-x-1/2',
  left: 'left-0 top-1/2 -translate-y-1/2',
  right: 'right-0 top-1/2 -translate-y-1/2',
  'top-right': '-top-20 left-1/2 -translate-x-1/2',
  'top-left': '-top-24 left-1/2 -translate-x-1/2',
  'bottom-right': '-bottom-20 left-1/2 -translate-x-1/2',
  'bottom-left': '-bottom-15 left-16',
} satisfies PositionClassesObj;

const positionMessageClasses = {
  top: '-top-full translate-y-6',
  'top-right': '-top-full translate-y-6 right-0',
  bottom: '-bottom-full -translate-y-10',
  left: 'left-0 top-1/2 -translate-y-1/2',
  right: 'right-0 top-1/2 -translate-y-1/2',
  'top-left': '-top-full translate-y-6 left-0',
  'bottom-right': '-bottom-full -translate-y-10 right-0',
  'bottom-left': '-bottom-full -translate-y-0 left-0',
} satisfies PositionClassesObj;

/**
 * # 코치마크
 *
 * 코치마크는 사용자에게 특정 UI 요소에 대한 추가 정보를 제공하는 컴포넌트입니다.
 *
 * @example
 * <CoachMark
 *   position='top-right'
 *   visible={true}
 *   onClose={() => console.log('close')}
 *   message='3개월 이용권 선택 시 최대 15%할인'
 * >
 *   <TargetComponent />
 * </CoachMark>
 *
 * @param {ReactNode} children - 코치마크가 감싸고 있는 컴포넌트. 필수 항목입니다.
 * @param {string} className - 코치마크의 CSS 클래스 이름입니다.
 * @param {boolean} visible - 코치마크의 초기 가시성을 설정합니다. 기본값은 true입니다.
 * @param {string} position - 코치마크의 위치를 설정합니다. 가능한 값은 'top', 'bottom', 'left', 'right', 'top-right', 'top-left', 'bottom-right', 'bottom-left'입니다. 기본값은 'top-right'입니다.
 * @param {string} message - 코치마크에 표시될 메시지입니다.
 * @param {ReactNode} element - 코치마크로 별도의 커스텀 컴포넌트를 사용할 때 사용됩니다.
 * @param {function} onClose - 코치마크가 닫힐 때 호출되는 함수입니다.
 * @returns {ReactElement} 코치마크 컴포넌트를 반환합니다.
 */
export const CoachMark = forwardRef<HTMLDivElement, CoachMarkProps>(
  (
    {
      children,
      className,
      visible = true,
      position = 'top-right',
      message,
      onClose,
      element,
      ...rest
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(visible);

    useEffect(() => {
      setIsOpen(visible);
    }, [visible]);

    const handleCloseButtonClick = () => {
      setIsOpen(false);
      if (onClose) onClose();
    };

    if (!children) throw new Error('CoachMark: children is required...');
    if (!isOpen) return <>{children}</>;

    const determinedPosition = position || 'top-right';

    const arrowPositionClasses = positionArrowClasses[determinedPosition];
    const messagePositionClasses = positionMessageClasses[determinedPosition];

    const showDot = !message && !onClose && !element;

    return (
      <div className={cn('relative', className)} ref={ref} {...rest}>
        {children}
        {showDot ? <NotificationDot /> : null}
        {message && !element ? (
          <>
            <Arrow className={arrowPositionClasses} />
            <Message
              className={messagePositionClasses}
              message={message}
              onClick={handleCloseButtonClick}
            />
          </>
        ) : null}
        {element ? element : null}
      </div>
    );
  },
);

const NotificationDot = () => {
  return <div className={cn('bg-DTRed-400 absolute -right-8 top-0 z-50 h-5 w-5 rounded-full')} />;
};

export const Arrow = ({ className, ...rest }: ComponentPropsWithoutRef<'div'>) => (
  <div
    className={cn(`bg-DTRed-400 absolute aspect-square h-12 rotate-45 rounded-[1.6px]`, className)}
    {...rest}
  />
);

export const Message = ({
  className,
  onClick,
  message,
  closeButton = true,
}: ComponentPropsWithoutRef<'button'> &
  Pick<CoachMarkProps, 'message'> & {
    closeButton?: boolean;
  }) => (
  <>
    <div
      id="CoachMark"
      className={cn(
        `bg-DTRed-400 absolute flex w-max items-center gap-8 rounded-[8px] px-12 py-8 text-[13px] font-[600] text-white`,
        className,
      )}
    >
      {message}
      {closeButton && (
        <button type="button" className="inline-flex" onClick={onClick}>
          <NewIcon icon="x-outlined" size={12} className="fill-new-white" />
        </button>
      )}
    </div>
  </>
);
