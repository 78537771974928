import { useEffect, useState } from 'react';

import { useGetAcademy, useGetUser } from '@db/collections';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { 후기최대갯수 } from 'shared-values';
import { useWindowSize } from 'usehooks-ts';

import { CoachMark } from '@components/CoachMark/CoachMark';

import { BOTTOM_NAV_BAR_HEIGHT } from '@constants';
import { isAppApproaching } from '@utils/Common';

import { BOTTOM_NAV_BAR_ITEMS } from '../BottomNavbar';

const BOTTOM_NAVBAR_SIDE_PADDING = 16;

const RIGHT_MARGIN = 25.5;

const BOX_SIZE = 14;

const THREE_SECONDS = 3000;

const FIVE_SECONDS = 5000;

const isAppApproach = isAppApproaching() && !window.isBottomNavbarVisible;

export const ReviewCoachMark = () => {
  const { user } = useGetUser();
  const { width } = useWindowSize();

  const router = useRouter();

  const [isVisible, setIsVisible] = useState(false);

  const canWriteReviewOrder = user?.orders.find((order) => order.reviewState === 'can-write');

  const { data: academy } = useGetAcademy(canWriteReviewOrder?.receipt.academyId);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, THREE_SECONDS);

    setTimeout(() => {
      setIsVisible(false);
    }, THREE_SECONDS + FIVE_SECONDS);
  }, []);

  if (!user || !user.canWriteReview || router.pathname !== '/' || !isVisible || !academy)
    return null;

  const { orders } = user;

  const isReviewWrittenBefore = orders.some(
    (order) => order.reviewState === 'under-review' || order.reviewState === 'complete',
  );

  if (isReviewWrittenBefore) return null;

  const maxWidth = (width > 500 ? 500 : width) - BOTTOM_NAVBAR_SIDE_PADDING * 2;

  const rightMargin = maxWidth / BOTTOM_NAV_BAR_ITEMS.length / 2;

  return (
    <div
      className="fixed z-10"
      style={{
        bottom: (!isAppApproach ? BOTTOM_NAV_BAR_HEIGHT : 0) + BOX_SIZE / 2,
        right:
          rightMargin +
          BOTTOM_NAVBAR_SIDE_PADDING -
          RIGHT_MARGIN -
          BOX_SIZE / 2 -
          (isAppApproach ? 12 : 0),
      }}
    >
      <CoachMark
        visible={isVisible}
        element={
          <div
            className={'bg-new-DTGreen-500 absolute rotate-45 rounded-[3px]'}
            style={{
              right: RIGHT_MARGIN,
              width: BOX_SIZE,
              height: BOX_SIZE,
              bottom: -BOX_SIZE / 2 + 2.5,
            }}
          />
        }
        className="bg-new-DTGreen-500 flex items-center justify-center gap-4 rounded-[8px] px-12 py-8 text-white"
        position="bottom-right"
        onClose={() => {}}
      >
        <Image
          src="/review/image-present.png"
          width={20}
          height={20}
          alt="지금 리뷰 남기면 커피 기프티콘 증정!"
        />
        <p className="text-new-Caption1-bold">
          지금 리뷰 남기면 커피 기프티콘 {academy.totalReviewCount > 후기최대갯수 ? '추첨' : '증정'}
          !
        </p>
      </CoachMark>
    </div>
  );
};
