import Head from 'next/head';
import { HOME_URI } from 'shared-values';

import { BOTTOM_NAV_BAR_ITEMS } from '../BottomNavbar';

/*
 * https://developers.google.com/search/docs/appearance/structured-data/breadcrumb?hl=ko 를 보고 서브링크 SEO를 위해 다음 Script tag를 추가합니다. */
export const Breadcrumbs = () => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            BOTTOM_NAV_BAR_ITEMS.map((item, index) => {
              return {
                '@type': 'ListItem',
                position: index + 1,
                name: item.text,
                item: new URL(HOME_URI + item.path).origin + new URL(HOME_URI + item.path).pathname,
              };
            }),
          ),
        }}
      />
    </Head>
  );
};
